var instagramFeed = {
	"user": "inkstress",
	"path" : "instagram-connect.php",
	"num" : "20",
	"cachetime" : "60",
	"media" : "photos",
	"showheader" : "false",
	"hoverdisplay" : "date",
	"showcaption" : "false",
	"showlikes" : "false",
	"lightboxcomments" : "false",
	"includewords" : "#inkstress",
	"excludewords" : "#announcements",
	"moderationmode" : "false",
	"hidephotos" : "sbi_1841001501369961597_34403745"
};